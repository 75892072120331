var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h1", [_vm._v("Privacy Policy for Easy Checkout Services")]),
      _c("p", { staticClass: "mt-4" }, [
        _vm._v(
          ' By clicking “Remember me”, you agree to these Terms of Service for using Easy Checkout services (the “Services”) offered by Brick Line Technology (us, we,” or “our”). The term "you" or "You" shall refer to any person or entity who views, uses, or accesses the Easy Checkout service and saves their debit/credit c or rd/other payment instrument data to the secure and PCI DSS-compliant environment of Brick Line Technology for processing payments in a more convenient way. These Terms of Service set forth the legally binding terms and conditions for Your use of the Service. '
        ),
      ]),
      _c("h3", { staticClass: "mt-2" }, [
        _vm._v("1. Authorization for Card Data Storage"),
      ]),
      _c("p", { staticClass: "mt-2" }, [
        _vm._v(
          " You are authorizing us to store your card data in our secure and PCI DSS-compliant card Card environment. You are aware of and agree to all the consequences in relation to saving card data in our system, including debits from your bank account or credit card for accepting payment for the service or product that you are availing of from the Merchants concerned as per the modality and terms and conditions stipulated by them. "
        ),
      ]),
      _c("h3", { staticClass: "mt-2" }, [_vm._v("2. Security Responsibility")]),
      _c("p", { staticClass: "mt-2" }, [
        _vm._v(
          " You have used your best possible and secured process to store your payment card data in our System, including but not limited to using a personal device free from any computer virus or any other malicious programs, updated internet browser, not using any public internet, an insecure Wi-Fi network, etc. In the event of any data breach that occurs due to Your negligence or on Your end, We shall have no liability for any such breach. "
        ),
      ]),
      _c("h3", { staticClass: "mt-2" }, [_vm._v("3. Mobile Verification")]),
      _c("p", { staticClass: "mt-2" }, [
        _vm._v(
          " Your card will be linked to your mobile phone number, which will be verified through a One-Time Password (OTP) sent to your mobile phone. To pay with Easy Checkout on a different computer or after you log out, just enter your mobile phone number during checkout, and Easy Checkout will instantly send you a text message to verify your identity. "
        ),
      ]),
      _c("h3", { staticClass: "mt-2" }, [
        _vm._v("4. Convenient Payment Process"),
      ]),
      _c("p", { staticClass: "mt-2" }, [
        _vm._v(
          " On your computer, laptop, or mobile phone, when you come back to Easy Checkout or to any other participating sites, your card information will already be filled in. You can just click Pay to confirm your purchase. You can edit the card number if you need to. You can save multiple cards under Easy Checkout if you wish to. We will have one of your cards as the default, which you frequently use. Using Easy Checkout, you will be enabled to have an Easy and Fast payment process by opting out of card 2FA for amounts lower than BDT 3,000. "
        ),
      ]),
      _c("h3", { staticClass: "mt-2" }, [
        _vm._v("5. Authority to Use Payment Card"),
      ]),
      _c("p", { staticClass: "mt-2" }, [
        _vm._v(
          " You are affirming that You have the absolute authority to use the saved payment card for any applicable means, which may include but not limited to making payment, tokenizing saved card data for processing your payment with a particular merchant, etc. If any unauthorized or unlawful use of Your payment card occurs outside of our environment, We shall not be liable in any circumstances. "
        ),
      ]),
      _c("h3", { staticClass: "mt-2" }, [
        _vm._v("6. Limitations of Responsibility"),
      ]),
      _c("p", { staticClass: "mt-2" }, [
        _vm._v(
          " You expressly acknowledge that Brick Line Technology is an online payment aggregation platform that works as an intermediary facilitating the transaction in a limited capacity only and does not bear any responsibility for product or service quality, delivery, or any other kind of logistical support on behalf of the Merchant (or seller). The purchase of any products or services with assistance regarding payment from Brick Line Technology Systems is a private contract between You and the concerned Merchant. As a Payment System Operator, We do not have any control over, determine, advise, or in any way involve ourselves in the offering or acceptance of commercial or contractual terms between You and the Merchant from whom you are purchasing service(s) or product(s). We do not make any representation or warranty as to the specifics (such as quality, quantity value, saleability, delivery, etc.) of the products or services proposed to be sold or sold by the Merchants through their respective websites. We do not warrant, endorse, guarantee, or assume responsibility for any product or service advertised or offered by the Merchants on their websites, and as with the purchase of a product or service through any medium or in any environment, You are advised to independently verify the bona fides of any particular Merchant and use Your best judgment and exercise caution as appropriate. "
        ),
      ]),
      _c("h3", { staticClass: "mt-2" }, [_vm._v("7. Contact Information")]),
      _c("p", { staticClass: "mt-2" }, [
        _vm._v(
          " For any queries, disputes, or claims, you may reach out to us at our hotline number, "
        ),
        _c("b", [_vm._v("+8801810193418")]),
        _vm._v(", or send us an email at "),
        _c("b", [_vm._v("contact@mouchak.com")]),
        _vm._v(" at any time. We are always there to address your issues. "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }