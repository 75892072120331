var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { class: _vm.classes.container },
    [
      _c("page-title", {
        attrs: {
          "page-title": "Purchase Package",
          "previous-location": "",
          divider: false,
        },
      }),
      _c(
        "v-card",
        {
          staticClass: "py-3",
          attrs: {
            flat: "",
            color: "transparent",
            width: _vm.sizes.sheet.width,
          },
        },
        [
          _c("submission-alert-message", {
            ref: "banner",
            attrs: { message: _vm.alertMessage, "should-scroll-to-top": "" },
            model: {
              value: _vm.showFormAlertMessage,
              callback: function ($$v) {
                _vm.showFormAlertMessage = $$v
              },
              expression: "showFormAlertMessage",
            },
          }),
        ],
        1
      ),
      _c(
        "v-sheet",
        { class: _vm.classes.sheet, attrs: { width: _vm.sizes.sheet.width } },
        [
          _c(
            "v-card",
            { class: _vm.classes.card, attrs: { flat: "", color: "primary" } },
            [
              _c("h2", { staticClass: "mb-1 white--text sm:text-base" }, [
                _vm._v(" Buy " + _vm._s(_vm.type.toUpperCase()) + " "),
              ]),
              _c(
                "p",
                {
                  staticClass:
                    "white--text xl:text-sm lg:text-sm md:text-xs sm:text-xs",
                },
                [_vm._v(" Update your subscription details below ")]
              ),
            ]
          ),
          _c(
            "v-card",
            { class: _vm.classes.details, attrs: { flat: "", color: "white" } },
            [
              _c(
                "v-row",
                {
                  staticClass:
                    "pb-5 border-solid border-0 border-b border-default",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("h2", { staticClass: "sm:text-base" }, [
                      _vm._v(_vm._s(_vm.type.toUpperCase()) + " Plan"),
                    ]),
                    _c("div", { staticClass: "mt-2" }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "rounded border border-solid border-primary primary--text py-1 px-2 xl:text-sm lg:text-sm md:text-xs sm:text-xs",
                        },
                        [_vm._v(_vm._s(_vm.planText))]
                      ),
                      _c(
                        "span",
                        {
                          staticClass:
                            "xl:text-sm lg:text-sm md:text-xs sm:text-xs ft-medium",
                          class: _vm.screenWidth < 600 ? "block mt-2" : "ml-2",
                        },
                        [_vm._v(_vm._s(_vm.priceText))]
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              !(_vm.screenWidth < 600)
                ? _c(
                    "v-row",
                    {
                      staticClass:
                        "pt-6 pb-5 border-solid border-0 border-b border-default",
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "8" } },
                        [
                          _c("h2", { staticClass: "mb-1" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.type.toUpperCase() == "SMS"
                                    ? "SMS"
                                    : "Student"
                                ) +
                                " Count "
                            ),
                          ]),
                          _c(
                            "p",
                            {
                              staticClass:
                                "xl:text-sm lg:text-sm md:text-xs sm:text-xs",
                            },
                            [_vm._v(" Update your subscription details ")]
                          ),
                          _c("v-slide-y-transition", [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.validating && !_vm.isOk.items,
                                    expression: "validating && !isOk.items",
                                  },
                                ],
                                staticClass:
                                  "decline--text xl:text-xs lg:text-xs md:text-xs sm:text-xs mt-2",
                                staticStyle: { width: "55%" },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.itemCountBadMessage) + " "
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex justify-end align-center",
                          attrs: { cols: "4" },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.items,
                                expression: "items",
                              },
                            ],
                            staticClass:
                              "plan-input-style rounded mr-1 xl:text-base lg:text-base md:text-sm sm:text-sm ft-medium",
                            class:
                              _vm.validating && !_vm.isOk.items
                                ? "plan-input-style-invalid"
                                : "plan-input-style-valid",
                            attrs: { type: "text", readonly: !_vm.isPurchase },
                            domProps: { value: _vm.items },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.items = $event.target.value
                              },
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "d-flex flex-column" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "rounded rounded-b-0",
                                  attrs: {
                                    "min-width": "40",
                                    width: "40",
                                    height: "24",
                                    outlined: "",
                                    color: "grey lighten-1",
                                    disabled: !_vm.isPurchase,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.countUp()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "primary" } },
                                    [_vm._v("mdi-plus")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "rounded rounded-t-0",
                                  attrs: {
                                    "min-width": "40",
                                    width: "40",
                                    height: "24",
                                    outlined: "",
                                    color: "grey lighten-1",
                                    disabled: !_vm.isPurchase,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.countDown()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "primary" } },
                                    [_vm._v("mdi-minus")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                { staticClass: "pt-6 pb-5", attrs: { "no-gutters": "" } },
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("h2", { staticClass: "mb-1 sm:text-base" }, [
                      _vm._v("Payment Details"),
                    ]),
                    !_vm.isPurchase
                      ? _c(
                          "div",
                          _vm._l(_vm.upgradeItems, function (upgrade, index) {
                            return _c("div", { key: index }, [
                              upgrade.num_students < _vm.items
                                ? _c(
                                    "div",
                                    { staticClass: "d-flex" },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "xl:text-sm lg:text-sm md:text-xs sm:text-xs",
                                        },
                                        [
                                          _vm._v(" Upgrade "),
                                          _vm.screenWidth < 600
                                            ? _c("br")
                                            : _vm._e(),
                                          _c("span", [
                                            _vm._v(
                                              "(" +
                                                _vm._s(
                                                  _vm.dateFormat(upgrade.from)
                                                ) +
                                                " to " +
                                                _vm._s(
                                                  _vm.dateFormat(upgrade.to)
                                                ) +
                                                ")"
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c("v-spacer", {
                                        staticClass:
                                          "border-0 border-b border-dashed border-dark",
                                      }),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "xl:text-sm lg:text-sm md:text-xs sm:text-xs d-flex align-end",
                                        },
                                        [
                                          _c("span", { staticClass: "mr-1" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.currencySymbol[_vm.currency]
                                              )
                                            ),
                                          ]),
                                          _vm._v(
                                            _vm._s(_vm.price) +
                                              "x" +
                                              _vm._s(
                                                Number(upgrade.mult).toFixed(2)
                                              ) +
                                              "x" +
                                              _vm._s(
                                                _vm.items - upgrade.num_students
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ])
                          }),
                          0
                        )
                      : _c(
                          "div",
                          { staticClass: "d-flex" },
                          [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "xl:text-sm lg:text-sm md:text-xs sm:text-xs",
                              },
                              [
                                _vm._v(
                                  " Subscription for " +
                                    _vm._s(
                                      _vm.type.toUpperCase() == "SMS"
                                        ? "SMS"
                                        : "Students"
                                    )
                                ),
                                _vm.screenWidth < 961 ? _c("br") : _vm._e(),
                                _c("span", [_vm._v(_vm._s(_vm.purchaseText))]),
                              ]
                            ),
                            _c("v-spacer", {
                              staticClass:
                                "border-0 border-b border-dashed border-dark",
                            }),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "xl:text-sm lg:text-sm md:text-xs sm:text-xs d-flex align-end",
                              },
                              [
                                _c("span", { staticClass: "mr-1" }, [
                                  _vm._v(
                                    _vm._s(_vm.currencySymbol[_vm.currency])
                                  ),
                                ]),
                                _vm._v(
                                  _vm._s(_vm.price) +
                                    "x" +
                                    _vm._s(_vm.items) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                    _c("div", {
                      staticClass:
                        "border-solid border-0 border-b border-default py-2",
                    }),
                    _c(
                      "div",
                      {
                        staticClass:
                          "mt-3 d-flex align-end justify-space-between xl:text-base lg:text-base md:text-sm sm:text-sm ft-bold",
                      },
                      [
                        _c("span", [_vm._v("Total")]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.currencySymbol[_vm.currency]) +
                              " " +
                              _vm._s(_vm.totalPrice)
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "pt-6 pb-5", attrs: { "no-gutters": "" } },
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("h2", { staticClass: "mb-1 sm:text-base" }, [
                      _vm._v("Payment Method"),
                    ]),
                    _c(
                      "p",
                      {
                        staticClass:
                          "xl:text-sm lg:text-sm md:text-xs sm:text-xs",
                      },
                      [_vm._v(" Update your subscription details below ")]
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "v-form",
                          { ref: "radio" },
                          [
                            _c(
                              "v-radio-group",
                              {
                                attrs: { rules: [_vm.required] },
                                model: {
                                  value: _vm.selectedMethod,
                                  callback: function ($$v) {
                                    _vm.selectedMethod = $$v
                                  },
                                  expression: "selectedMethod",
                                },
                              },
                              [
                                _c("v-radio", {
                                  attrs: { color: "primary", value: "bkash" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _c("v-img", {
                                            attrs: {
                                              src: _vm.icons.bKashIcon,
                                              alt: "bKash",
                                              height:
                                                _vm.sizes.bKashIcon.height,
                                              "max-width":
                                                _vm.sizes.bKashIcon.width,
                                            },
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "ml-2 ft-medium sm:text-sm",
                                              class: {
                                                "primary--text":
                                                  _vm.selectedMethod == "bkash",
                                              },
                                            },
                                            [_vm._v("BKash")]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                }),
                                _c("v-radio", {
                                  attrs: { color: "primary", value: "nagad" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _c("v-img", {
                                            attrs: {
                                              src: _vm.icons.nagadIcon,
                                              alt: "bKash",
                                              height:
                                                _vm.sizes.nagadIcon.height,
                                              "max-width":
                                                _vm.sizes.nagadIcon.width,
                                            },
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "ml-2 ft-medium sm:text-sm",
                                              class: {
                                                "primary--text":
                                                  _vm.selectedMethod == "nagad",
                                              },
                                            },
                                            [_vm._v("Nagad")]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                }),
                                _vm._l(
                                  _vm.additionalMethods,
                                  function (item, index) {
                                    return _c("v-radio", {
                                      key: index,
                                      attrs: {
                                        color: "primary",
                                        value: item.value,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "ft-medium sm:text-sm",
                                                    class: {
                                                      "primary--text":
                                                        _vm.selectedMethod ==
                                                        item.value,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.name) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  }
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex align-center justify-start",
                      attrs: { cols: "1" },
                    },
                    [
                      _c("v-simple-checkbox", {
                        attrs: { dense: "", color: "primary" },
                        model: {
                          value: _vm.userCompliance,
                          callback: function ($$v) {
                            _vm.userCompliance = $$v
                          },
                          expression: "userCompliance",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex align-center justify-start",
                      class: { "pa-0 pl-1": _vm.screenWidth < 600 },
                      attrs: { cols: _vm.screenWidth < 600 ? "11" : "10" },
                    },
                    [
                      _c("span", { staticClass: "inert sm:text-xs" }, [
                        _vm._v("I hearby accept all"),
                      ]),
                      _c(
                        "span",
                        {
                          staticClass:
                            "clickable blue--text lighten-1 ml-1 sm:text-xs",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.showLegalNotification = true
                            },
                          },
                        },
                        [_vm._v("Terms & Conditions")]
                      ),
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-0 pt-2",
                      staticStyle: { height: "24px" },
                      attrs: { cols: "12" },
                    },
                    [
                      _c("v-slide-y-transition", [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.validating && !_vm.isOk.agree,
                                expression: "validating && !isOk.agree",
                              },
                            ],
                            staticClass:
                              "decline--text xl:text-xs lg:text-xs md:text-xs sm:text-xs",
                          },
                          [_vm._v(" " + _vm._s(_vm.notAgreeMessage) + " ")]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mt-6", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex justify-end",
                      attrs: { cols: "12" },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text pay-btn",
                          attrs: {
                            depressed: "",
                            color: "primary",
                            loading: _vm.saving,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.save()
                            },
                          },
                        },
                        [_vm._v("Pay Now")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-3 pay-btn",
                          attrs: {
                            depressed: "",
                            outlined: "",
                            color: "grey lighten-1",
                          },
                          on: { click: _vm.goBack },
                        },
                        [
                          _c("span", { staticClass: "black--text" }, [
                            _vm._v("Cancel"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: _vm.sizes.dialog },
          model: {
            value: _vm.showLegalNotification,
            callback: function ($$v) {
              _vm.showLegalNotification = $$v
            },
            expression: "showLegalNotification",
          },
        },
        [
          _c(
            "v-sheet",
            { staticClass: "bg-white sm:text-sm", class: _vm.classes.dialog },
            [_c("payment-transaction-policy")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }