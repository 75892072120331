<template>
  <v-container :class="classes.container">
    <page-title
      page-title="Purchase Package"
      previous-location
      :divider="false"
    ></page-title>
    <v-card flat color="transparent" class="py-3" :width="sizes.sheet.width">
      <submission-alert-message
        ref="banner"
        v-model="showFormAlertMessage"
        :message="alertMessage"
        should-scroll-to-top
      ></submission-alert-message>
    </v-card>
    <v-sheet :class="classes.sheet" :width="sizes.sheet.width">
      <v-card flat color="primary" :class="classes.card">
        <h2 class="mb-1 white--text sm:text-base">
          Buy {{ type.toUpperCase() }}
        </h2>
        <p class="white--text xl:text-sm lg:text-sm md:text-xs sm:text-xs">
          Update your subscription details below
        </p>
      </v-card>
      <v-card flat color="white" :class="classes.details">
        <v-row
          no-gutters
          class="pb-5 border-solid border-0 border-b border-default"
        >
          <v-col cols="12">
            <h2 class="sm:text-base">{{ type.toUpperCase() }} Plan</h2>
            <div class="mt-2">
              <span
                class="rounded border border-solid border-primary primary--text py-1 px-2 xl:text-sm lg:text-sm md:text-xs sm:text-xs"
                >{{ planText }}</span
              >
              <span
                class="xl:text-sm lg:text-sm md:text-xs sm:text-xs ft-medium"
                :class="screenWidth < 600 ? 'block mt-2' : 'ml-2'"
                >{{ priceText }}</span
              >
            </div>
          </v-col>
        </v-row>
        <v-row
          v-if="!(screenWidth < 600)"
          class="pt-6 pb-5 border-solid border-0 border-b border-default"
          no-gutters
        >
          <v-col cols="8">
            <h2 class="mb-1">
              {{ type.toUpperCase() == "SMS" ? "SMS" : "Student" }} Count
            </h2>
            <p class="xl:text-sm lg:text-sm md:text-xs sm:text-xs">
              Update your subscription details
            </p>
            <v-slide-y-transition>
              <div
                v-show="validating && !isOk.items"
                class="decline--text xl:text-xs lg:text-xs md:text-xs sm:text-xs mt-2"
                style="width: 55%"
              >
                {{ itemCountBadMessage }}
              </div>
            </v-slide-y-transition>
          </v-col>
          <v-col cols="4" class="d-flex justify-end align-center">
            <input
              v-model="items"
              type="text"
              class="plan-input-style rounded mr-1 xl:text-base lg:text-base md:text-sm sm:text-sm ft-medium"
              :class="
                validating && !isOk.items
                  ? 'plan-input-style-invalid'
                  : 'plan-input-style-valid'
              "
              :readonly="!isPurchase"
            />
            <div class="d-flex flex-column">
              <v-btn
                min-width="40"
                width="40"
                height="24"
                outlined
                class="rounded rounded-b-0"
                color="grey lighten-1"
                :disabled="!isPurchase"
                @click="countUp()"
                ><v-icon color="primary">mdi-plus</v-icon></v-btn
              >
              <v-btn
                min-width="40"
                width="40"
                height="24"
                outlined
                class="rounded rounded-t-0"
                color="grey lighten-1"
                :disabled="!isPurchase"
                @click="countDown()"
                ><v-icon color="primary">mdi-minus</v-icon></v-btn
              >
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-6 pb-5">
          <v-col cols="12">
            <h2 class="mb-1 sm:text-base">Payment Details</h2>
            <div v-if="!isPurchase">
              <div v-for="(upgrade, index) in upgradeItems" :key="index">
                <div v-if="upgrade.num_students < items" class="d-flex">
                  <p class="xl:text-sm lg:text-sm md:text-xs sm:text-xs">
                    Upgrade <br v-if="screenWidth < 600" /><span
                      >({{ dateFormat(upgrade.from) }} to
                      {{ dateFormat(upgrade.to) }})</span
                    >
                  </p>
                  <v-spacer
                    class="border-0 border-b border-dashed border-dark"
                  ></v-spacer>
                  <p
                    class="xl:text-sm lg:text-sm md:text-xs sm:text-xs d-flex align-end"
                  >
                    <span class="mr-1">{{ currencySymbol[currency] }}</span
                    >{{ price }}x{{ Number(upgrade.mult).toFixed(2) }}x{{
                      items - upgrade.num_students
                    }}
                  </p>
                </div>
              </div>
            </div>
            <div v-else class="d-flex">
              <p class="xl:text-sm lg:text-sm md:text-xs sm:text-xs">
                Subscription for
                {{ type.toUpperCase() == "SMS" ? "SMS" : "Students"
                }}<br v-if="screenWidth < 961" /><span>{{ purchaseText }}</span>
              </p>
              <v-spacer
                class="border-0 border-b border-dashed border-dark"
              ></v-spacer>
              <p
                class="xl:text-sm lg:text-sm md:text-xs sm:text-xs d-flex align-end"
              >
                <span class="mr-1">{{ currencySymbol[currency] }}</span
                >{{ price }}x{{ items }}
              </p>
            </div>
            <div
              class="border-solid border-0 border-b border-default py-2"
            ></div>
            <div
              class="mt-3 d-flex align-end justify-space-between xl:text-base lg:text-base md:text-sm sm:text-sm ft-bold"
            >
              <span>Total</span>
              <span>{{ currencySymbol[currency] }} {{ totalPrice }}</span>
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-6 pb-5">
          <v-col cols="12">
            <h2 class="mb-1 sm:text-base">Payment Method</h2>
            <p class="xl:text-sm lg:text-sm md:text-xs sm:text-xs">
              Update your subscription details below
            </p>
            <div>
              <v-form ref="radio">
                <v-radio-group :rules="[required]" v-model="selectedMethod">
                  <v-radio color="primary" value="bkash">
                    <template #label>
                      <v-img
                        :src="icons.bKashIcon"
                        alt="bKash"
                        :height="sizes.bKashIcon.height"
                        :max-width="sizes.bKashIcon.width"
                      ></v-img
                      ><span
                        class="ml-2 ft-medium sm:text-sm"
                        :class="{ 'primary--text': selectedMethod == 'bkash' }"
                        >BKash</span
                      >
                    </template>
                  </v-radio>
                  <v-radio color="primary" value="nagad">
                    <template #label>
                      <v-img
                        :src="icons.nagadIcon"
                        alt="bKash"
                        :height="sizes.nagadIcon.height"
                        :max-width="sizes.nagadIcon.width"
                      ></v-img
                      ><span
                        class="ml-2 ft-medium sm:text-sm"
                        :class="{ 'primary--text': selectedMethod == 'nagad' }"
                        >Nagad</span
                      >
                    </template>
                  </v-radio>
                  <v-radio
                    v-for="(item, index) in additionalMethods"
                    :key="index"
                    color="primary"
                    :value="item.value"
                  >
                    <template #label>
                      <div
                        class="ft-medium sm:text-sm"
                        :class="{
                          'primary--text': selectedMethod == item.value,
                        }"
                      >
                        {{ item.name }}
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-form>
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="1" class="d-flex align-center justify-start">
            <v-simple-checkbox v-model="userCompliance" dense color="primary">
            </v-simple-checkbox>
          </v-col>
          <v-col
            :cols="screenWidth < 600 ? '11' : '10'"
            class="d-flex align-center justify-start"
            :class="{ 'pa-0 pl-1': screenWidth < 600 }"
          >
            <span class="inert sm:text-xs">I hearby accept all</span>
            <span
              class="clickable blue--text lighten-1 ml-1 sm:text-xs"
              @click.prevent="showLegalNotification = true"
              >Terms & Conditions</span
            >
          </v-col>
          <v-col cols="12" class="pa-0 pt-2" style="height: 24px">
            <v-slide-y-transition>
              <div
                v-show="validating && !isOk.agree"
                class="decline--text xl:text-xs lg:text-xs md:text-xs sm:text-xs"
              >
                {{ notAgreeMessage }}
              </div>
            </v-slide-y-transition>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-6">
          <v-col cols="12" class="d-flex justify-end">
            <v-btn
              depressed
              color="primary"
              class="white--text pay-btn"
              :loading="saving"
              @click="save()"
              >Pay Now</v-btn
            >
            <v-btn
              depressed
              outlined
              color="grey lighten-1"
              class="ml-3 pay-btn"
              @click="goBack"
              ><span class="black--text">Cancel</span></v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-sheet>
    <v-dialog v-model="showLegalNotification" :width="sizes.dialog">
      <v-sheet class="bg-white sm:text-sm" :class="classes.dialog">
        <payment-transaction-policy></payment-transaction-policy>
      </v-sheet>
    </v-dialog>
  </v-container>
</template>

<script>
import PageTitle from "@ef/global/components/PageTitle.vue";
import SubmissionAlertMessage from "/global/components/SubmissionAlertMessage";
import PaymentTransactionPolicy from "#ecf/payment-transation/components/PaymentTransactionPolicy.vue";
import { FieldValidations } from "/global/utils/validations.js";

export default {
  name: "SubscriptionPurchasePage",
  components: {
    PageTitle,
    SubmissionAlertMessage,
    PaymentTransactionPolicy
  },
  mixins: [FieldValidations],
  props: {
    type: {
      type: String,
      default: undefined,
    },
    price: {
      type: String,
      default: "0.00",
    },
    currency: {
      type: String,
    },
    countNew: {
      type: Number,
    },
    countOriginal: {
      type: Number,
    },
    isPurchase: {
      type: Boolean,
    },
    upgradeList: {
      type: Array,
      default: undefined,
    },
    purchaseText: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      items: undefined,
      selectedMethod: undefined,
      additionalMethods: [
        {
          name: "Credit/Debit Cards",
          value: "card",
        },
        {
          name: "Internet Banking",
          value: "internet",
        },
        {
          name: "Mobile Banking",
          value: "mobile",
        },
      ],
      userCompliance: false,
      saving: false,
      showFormAlertMessage: false,
      showLegalNotification: false,
      validating: false,
    };
  },
  computed: {
    screenWidth() {
      return this.$vuetify.breakpoint.width;
    },
    sizes() {
      return {
        sheet: {
          width: this.breakPointValues("100%", 400, 500, 650),
        },
        bKashIcon: {
          width: this.breakPointValues(30, 30, 30, 30),
          height: this.breakPointValues(25, 25, 25, 25),
        },
        nagadIcon: {
          width: this.breakPointValues(30, 30, 30, 30),
          height: this.breakPointValues(25, 25, 25, 25),
        },
        renewBtn: {
          minWidth: 30,
          width: this.breakPointValues(50, 50, 50, 50),
          height: this.breakPointValues(25, 25, 25, 25),
        },
        dialog: this.breakPointValues("75%", "75%", "50%", "50%"),
      };
    },
    classes() {
      return {
        container: `pa-${this.breakPointValues(0, 2, 4, 8)}`,
        sheet: `bg-white pa-${this.breakPointValues(3, 3, 3, 3)}`,
        card: `px-${this.breakPointValues(
          3,
          4,
          4,
          5
        )} py-${this.breakPointValues(3, 4, 4, 5)}`,
        details: `pa-${this.breakPointValues(3, 4, 4, 5)}`,
        renewBtn: ["white--text", "black--text"],
        dialog: `pa-${this.breakPointValues(5, 5, 5, 5)}`,
      };
    },
    colors() {
      return {
        renewBtn: [this.isPurchase ? "primary" : "grey lighten-1", "white"],
      };
    },
    icons() {
      return {
        bKashIcon: require("#ecf/payment-transation/assets/icons/bkash.png"),
        nagadIcon: require("#ecf/payment-transation/assets/icons/nagad.png"),
      };
    },
    planText() {
      return this.type.toUpperCase() == "SMS"
        ? "SMS Subscription"
        : "Student Subscription";
    },
    priceText() {
      return this.type.toUpperCase() == "SMS"
        ? `${this.price}/Per SMS`
        : `Monthly ${this.price}/Per Student`;
    },
    currencySymbol() {
      return {
        bdt: "৳",
        usd: "$",
      };
    },
    upgradeItems() {
      if (this.upgradeList === undefined) return [];
      return this.upgradeList.slice(0, 1);
    },
    totalPrice() {
      let count = this.items - this.countOriginal;
      if (!this.isPurchase && count > 0) {
        let total = 0.0;
        for (let item of this.upgradeItems) {
          if (item.num_students < this.items) {
            total =
              total + (this.items - item.num_students) * this.price * item.mult;
          }
        }
        return String((Math.ceil(total * 100) / 100.0).toFixed(2));
      }
      return String((Number(this.price) * this.items).toFixed(2));
    },
    successMessage() {
      let item_type =
        this.type.toUpperCase() == "SMS" ? "messages" : "students";
      return `Successfuly added ${this.items} ${item_type}`;
    },
    alertMessage() {
      return "Please Complete the form";
    },
    itemCountBadMessage() {
      let min = this.type.toUpperCase() != "SMS" && this.isPurchase ? 10 : 1;
      let label =
        this.type.toUpperCase() == "SMS"
          ? "Purchased messages"
          : "Maximum enrollable students";
      return this.betweenValue(this.items, min, 10 ** 8 - 1, true, label);
    },
    notAgreeMessage() {
      return this.required(this.userCompliance);
    },
    isOk() {
      return {
        items:
          (this.type.toUpperCase() != "SMS" && this.isPurchase
            ? this.items >= 10
            : this.items > 0) && this.items < 10 ** 8,
        method: !(this.selectedMethod === undefined),
        agree: this.userCompliance,
      };
    },
    allowSave() {
      return this.isOk.items && this.isOk.method && this.isOk.agree;
    },
  },
  watch: {
    items: {
      handler(value, old) {
        if (value == "" || value === undefined) {
          this.items = 0;
        } else if (isNaN(parseInt(value))) {
          this.items = old;
        } else {
          this.items = parseInt(value);
        }
      },
    },
    selectedMethod: {
      handler(value) {
        if (value !== undefined) return;
      },
    },
    userCompliance: {
      handler(value) {
        if (value) return;
      },
    },
    allowSave: {
      handler(value) {
        if (value) {
          this.showFormAlertMessage = false;
          this.validating = false;
        }
      },
    },
  },
  created() {
    if (this.type === undefined) {
      this.goBack();
    }
    this.items = this.countNew;
  },
  methods: {
    async save() {
      if (!this.allowSave) {
        this.triggerAlert();
        return;
      }
      if (!this.saving) {
        this.saving = true;
        if (this.isPurchase && this.type.toUpperCase() != "SMS") {
          await this.pay();
        } else {
          await this.update();
        }
      }
    },
    async update() {
      try {
        let action =
          this.type.toUpperCase() == "SMS" ? "buySms" : "updateSubscription";
        let countItem =
          this.type.toUpperCase() == "SMS" ? "countMessages" : "numStudents";
        let outgoingData = {
          paymentMethod: ["card", "internet", "mobile"].includes(
            String(this.selectedMethod)
          )
            ? "sslcommerz"
            : String(this.selectedMethod),
        };
        outgoingData[countItem] = this.items;
        let resp = await this.$store.dispatch(
          `transaction/${action}`,
          outgoingData
        );
        window.location.href = resp.gatewayURL;
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.saving = false;
      }
    },
    async pay() {
      try {
        if (this.items < 10) {
          this.$root.$emit("alert", [
            undefined,
            "Number of student must be at least 10",
          ]);
          this.saving = false;
          return;
        } else if (this.items > 10 ** 8) {
          this.$root.$emit("alert", [
            undefined,
            `Number of student must be less than ${
              10 ** 8
            }(one hundred million)`,
          ]);
          this.saving = false;
          return;
        } else if (this.items > 10 ** 8) {
          this.$root.$emit("alert", [
            undefined,
            `Number of student must be less than ${
              10 ** 8
            }(one hundred million)`,
          ]);
          this.saving = false;
          return;
        }

        let resp = await this.$store.dispatch(
          "transaction/purchaseSubscription",
          {
            numStudents: this.items,
            paymentMethod: ["card", "internet", "mobile"].includes(
              String(this.selectedMethod)
            )
              ? "sslcommerz"
              : String(this.selectedMethod),
          }
        );
        window.location.href = resp.gatewayURL;
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.saving = false;
      }
    },
    goBack() {
      this.$router.push({
        name: "teacher-payment-transaction-page",
      });
    },
    async triggerAlert() {
      this.validating = true;
      this.$refs.radio.validate();
      if (!this.showFormAlertMessage) this.showFormAlertMessage = true;
      else {
        await this.$refs.banner.flash();
      }
    },
    breakPointValues(smValue, mdValue, lgValue, xlValue) {
      let s = this.screenWidth;
      return s < 600
        ? smValue
        : s < 961
        ? mdValue
        : s < 1903
        ? lgValue
        : xlValue;
    },
    countUp() {
      this.items++;
    },
    countDown() {
      this.items = Math.max(0, this.items - 1);
    },
    dateFormat(payload) {
      let parts = payload.split("-");
      return new Date(
        parts[0],
        String(Number(parts[1]) - 1),
        parts[2]
      ).toLocaleString("en-gb", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.plan-input-style {
  height: 70px;
  width: 70px;
  max-width: 120px;
  text-align: center;
}
.plan-input-style-valid {
  border: 1px solid $border;
}
.plan-input-style-valid:hover {
  outline: none;
}
.plan-input-style-valid:focus {
  outline: none;
  border: 2px solid $primary;
}
.plan-input-style-invalid {
  border: 2px solid $decline;
}
.plan-input-style-invalid:hover {
  outline: none;
}
.plan-input-style-invalid:focus {
  outline: none;
  // border: 2px solid $decline;
}

.auto-renew-btn-style::before {
  display: none;
}

.pay-btn {
  @media screen and (max-width: 599px) {
    width: calc(50% - 6px);
  }
}
</style>
